import React from 'react';
import { NextPage } from 'next';
import Link from 'next/link';
import { Button, Container, Text } from '@chakra-ui/react';
import { Layout } from '@/components/layout';
import { Routes } from '@/constants';

const ErrorPage: NextPage = () => (
  <Layout tabTitle="404">
    <Container pt="50px" textAlign="center" fontSize="24px" flexDir="column" alignItems="center">
      <Text>404 | Page was not found</Text>
      <Link href={Routes.Questions} passHref legacyBehavior>
        <Button as="a" mt="20px" colorScheme="red" variant="solid">
          Go to questions
        </Button>
      </Link>
    </Container>
  </Layout>
);

export default ErrorPage;
